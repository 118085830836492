import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Form, Button, Breadcrumb } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import { useLocation } from "@reach/router"
import SocialShare from "../common/socialShare";
import "./PropertyNav.scss"
// Header component

const PropertyNav = (props) => {

  useEffect(() => {
    var urlSearchParams = new URLSearchParams(window.location.search);
    var params = Object.fromEntries(urlSearchParams.entries());
    //console.log("params",params)
    if(params?.target){
       setTimeout(() =>{
       const element = document.getElementById("enquire-now");
       element.scrollIntoView();
    },500)
  } 
})

  let findUrl = ""

  if(props?.propertyData?.search_type === "sales" && props?.propertyData?.department === "residential") {
    findUrl = "/residential-property/for-sale/in-london/"
  } else if(props?.propertyData?.search_type === "lettings" && props?.propertyData?.department === "residential") {
    findUrl = "/residential-property/to-rent/in-london/"
  } else if(props?.propertyData?.search_type === "sales" && props?.propertyData?.department === "commercial") {
    findUrl = "/commercial-property/for-sale/in-london/"
  } else if(props?.propertyData?.search_type === "lettings" && props?.propertyData?.department === "commercial") {
    findUrl = "/commercial-property/to-rent/in-london/"
  } else if(props?.propertyData?.search_type === "sales" && props?.propertyData?.department === "auction_residential") {
    findUrl = "/auction-residential-property/for-sale/in-uk/"
  } else if(props?.propertyData?.search_type === "sales" && props?.propertyData?.department === "auction_commercial") {
    findUrl = "/auction-commercial-property/for-sale/in-uk/"
  }

  if(props.page_url){
    findUrl = props.page_url;
  }
  
  const location = useLocation();
    
  const [Shareicons,setShareicons] = React.useState(false);
  
  const openShareicons = () => {
    setShareicons(true);
    if(Shareicons === true) {
      setShareicons(false);
    }
  }

  const [modalBookViewformOpen,setBookViewingformOpen] = React.useState(false);
  
  const openBookViewformModal = () => {
      setBookViewingformOpen(true);
  }

  return (
    <React.Fragment>
      {
        props?.propertyData &&
        <section className="property-nav">
          <Container>
            <Row>
              <Col>
                  <div className="sm-menu-list d-lg-none">
                    {
                      props?.propertyData?.department === "auction_residential" || props?.propertyData?.department === "auction_commercial" ?
                      // <span className="sm-text" onClick={(e) => window.history.back(-1)}>  &lt; return to Catalogue  </span>
                      <a className="sm-text" href={"javascript:;"} onClick={(e) => window.history.back(-1)}>  &lt; return to Catalogue  </a>
                      :
                      <span className="sm-text" onClick={(e) => window.history.back(-1)}>  &lt; back to listing  </span>
                    }
                    
                    {
                      props?.propertyData?.department === "auction_residential" || props?.propertyData?.department === "auction_commercial" ? "" :
                      <span className="share social-share-wrapper">
                        <div className="d-flex align-items-center">
                          <a className="d-flex align-items-center" href="javascript:void(0)" onClick={openShareicons}><i className="icon-share"></i> share this property</a>
                        </div>
                        {Shareicons && (
                          <SocialShare openShareicons={openShareicons} />
                        )}
                      </span>
                    }
                  </div>
                  <div className="breadcrumb d-none d-lg-flex">
                    <Row className="w-100">
                      <Col xl={10} lg={9} className="d-flex justify-content-start pe-5">
                      {
                      props?.propertyData?.department === "auction_residential" || props?.propertyData?.department === "auction_commercial" ? 
                      <Breadcrumb>
                        <Breadcrumb.Item href={findUrl} > &lt; Return to Catalogue </Breadcrumb.Item>
                      </Breadcrumb>
                      // <span className="sm-text" onClick={(e) => window.history.back(-1)}>  &lt; Return to Catalogue  </span>
                      :
                      <Breadcrumb className="property-details-nav">
                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                        <li className="breadcrumb-item"><Link to={findUrl}>find a property</Link></li>
                        <li className="breadcrumb-item"><Link to={findUrl}>{props?.propertyData?.department.replace("_", " ")} property {props?.propertyData?.search_type === "sales" ? "for sale" : "to rent"} in UK</Link></li>
                        <li className="breadcrumb-item active"><a>{props?.propertyData?.display_address}</a></li>
                      </Breadcrumb>
                    }
                      </Col>
                      <Col xl={2} lg={3} className="d-flex align-items-center">
                      {
                      
                      <span className="share social-share-wrapper">
                        <ScrollAnimation animateIn='bounce' initiallyVisible={true}>
                          <div className="d-flex align-items-center">
                            <a className="d-flex align-items-center" href="javascript:void(0)" onClick={openShareicons}><i className="icon-share"></i></a> <a className="d-flex align-items-center" href="javascript:void(0)" onClick={openShareicons}>share this property</a>
                          </div>
                        </ScrollAnimation>
                        {Shareicons && (
                          <SocialShare openShareicons={openShareicons} />
                        )}
                      </span>
                    }
                      </Col>
                    </Row>                 
                  </div>
              </Col>
            </Row>
          </Container>
        </section>
      }
    </React.Fragment>
  );
};
export default PropertyNav;
